import React, { useState, useEffect } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { ArrowRight } from "react-bootstrap-icons";
import { Download } from "react-bootstrap-icons";
// import { FaGem } from "react-bootstrap-icons";
// import { FaHeart } from "react-bootstrap-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import { Fade, Slide } from "react-awesome-reveal";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import Cookies from "universal-cookie";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import LoadingOverlay from "react-loading-overlay";
import { useInterval } from "../hooks/useInterval";
// import { ReCaptcha } from "react-recaptcha-v3";
//https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react
const SITE_KEY = "6LcUAdMUAAAAAERazyxIznB6bbDciTdd045kKexj";

export default function Dashboard(props) {
  const [controllerName, setControllerName] = React.useState("");
  const [controllerReady, setControllerReady] = React.useState(false);
  const [controllerStatus, setControllerStatus] = React.useState("");
  const [initialPassword, setInitialPassword] = React.useState("");
  const [externalBackups, setExternalBackups] = React.useState([]);
  //   const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    fetch("/api/controllers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //   body: JSON.stringify(json),
    })
      .then((r) => r.json())
      .then((data) => {
        console.log("data");
        console.log(data);
        // cookies.set("controllificUser", JSON.stringify(data), {
        //   maxAge: 30 * 24 * 60 * 60,
        //   path: "/",
        // });
        setControllerName(data.controllerNames);
        console.log("data.controllerNames", data.controllerNames[0]);
        //   props.history.push(`/dashboard`);
        // history.push("/dashboard");
      })
      .catch((err) => {
        console.log("error: ", err);
        props.history.push("/user?login");
        // this.setState({ loginFailure: true });
      });
  }, []);

  React.useEffect(() => {
    fetch(`/api/controllers/${controllerName}/initialPassword`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //   body: JSON.stringify(json),
    })
      .then((r) => r.json())
      .then((data) => {
        console.log("initpass");
        console.log(data);
        // cookies.set("controllificUser", JSON.stringify(data), {
        //   maxAge: 30 * 24 * 60 * 60,
        //   path: "/",
        // });
        setInitialPassword(data.initialPassword);
        //   props.history.push(`/dashboard`);
        // history.push("/dashboard");
      })
      .catch((err) => {
        console.log("error: ", err);
        // props.history.push("/user?login");
        // this.setState({ loginFailure: true });
      });
  }, [controllerName]);

  React.useEffect(() => {
    fetch(`/api/controllers/backups`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //   body: JSON.stringify(json),
    })
      .then((r) => r.json())
      .then((data) => {
        console.log("initpass");
        console.log(data);
        // cookies.set("controllificUser", JSON.stringify(data), {
        //   maxAge: 30 * 24 * 60 * 60,
        //   path: "/",
        // });
        setExternalBackups(data.backupFiles);
        //   props.history.push(`/dashboard`);
        // history.push("/dashboard");
      })
      .catch((err) => {
        console.log("error: ", err);
        // props.history.push("/user?login");
        // this.setState({ loginFailure: true });
      });
  }, [controllerName]);

  //   const tick = () => {
  //     //let newCount = count < 60 ? count + 1 : 0
  //     setCount((prevState) => (prevState < 60 ? prevState + 1 : 0));
  //   };

  //   React.useEffect(() => {
  //     const timer = setInterval(() => tick(), 100);
  //     return () => clearInterval(timer);
  //   });

  //   React.useEffect(() => {
  //     if (count === 0) {
  //       /*post('/api/return-url/', { id: [id] })
  //         .then(res => {
  //           if (res && res.content) {
  //             setUrl(res.content.url)
  //           }
  //         })
  //       */
  //       //   mockAPI().then((data) => setUrl(data));
  //       if (controllerName !== "")
  //         fetch(`/api/controller/${controllerName}/status`, {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           //   body: JSON.stringify(json),
  //         })
  //           .then((r) => r.json())
  //           .then((data) => {
  //             console.log("data");
  //             console.log(data);
  //             // cookies.set("controllificUser", JSON.stringify(data), {
  //             //   maxAge: 30 * 24 * 60 * 60,
  //             //   path: "/",
  //             // });
  //             // setControllerName(data.controllerNames);
  //             setControllerStatus(data.status);
  //             if (data.status === "deployed") {
  //               setControllerReady(true);
  //             }

  //             // if (data.status === "deployed") {
  //             //     setControllerReady(true);
  //             //   }
  //             console.log("data.status", data);
  //             //   props.history.push(`/dashboard`);
  //             // history.push("/dashboard");
  //           })
  //           .catch((err) => {
  //             console.log("error: ", err);
  //             // props.history.push("/user?login");
  //             // this.setState({ loginFailure: true });
  //           });
  //     }
  //   }, [count]);

  const foo = async () => {
    if (!controllerReady) {
      fetch(`/api/controller/${controllerName}/status`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        //   body: JSON.stringify(json),
      })
        .then((r) => r.json())
        .then((data) => {
          console.log("data");
          console.log(data);
          // cookies.set("controllificUser", JSON.stringify(data), {
          //   maxAge: 30 * 24 * 60 * 60,
          //   path: "/",
          // });
          // setControllerName(data.controllerNames);
          setControllerStatus(data.status);
          if (data.status === "deployed") {
            setControllerReady(true);
            console.log("controllerReady", controllerReady);
          }

          // if (data.status === "deployed") {
          //     setControllerReady(true);
          //   }
          console.log("data.status", data);
          //   props.history.push(`/dashboard`);
          // history.push("/dashboard");
        })
        .catch((err) => {
          console.log("error: ", err);
          // props.history.push("/user?login");
          // this.setState({ loginFailure: true });
        });
    } else {
      //   console.log("we are done");
    }
  };

  useInterval(foo, 1000);

  //   React.useEffect(() => {
  //     fetch(`/api/controller/${controllerName}/status`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       //   body: JSON.stringify(json),
  //     })
  //       .then((r) => r.json())
  //       .then((data) => {
  //         console.log("data");
  //         console.log(data);
  //         // cookies.set("controllificUser", JSON.stringify(data), {
  //         //   maxAge: 30 * 24 * 60 * 60,
  //         //   path: "/",
  //         // });
  //         // setControllerName(data.controllerNames);
  //         setControllerStatus(data.status);
  //         if (data.status === "deployed") {
  //           setControllerReady(true);
  //         }

  //         // if (data.status === "deployed") {
  //         //     setControllerReady(true);
  //         //   }
  //         console.log("data.status", data);
  //         //   props.history.push(`/dashboard`);
  //         // history.push("/dashboard");
  //       })
  //       .catch((err) => {
  //         console.log("error: ", err);
  //         // props.history.push("/user?login");
  //         // this.setState({ loginFailure: true });
  //       });
  //   }, [controllerName]);

  return (
    <React.Fragment>
      {/* {externalBackups.map((value, i) => (
        <div key={value}>{value}</div>
      ))} */}
      <LoadingOverlay
        active={!controllerReady}
        fadeSpeed={0}
        spinner
        text={
          controllerStatus !== "" && controllerStatus !== "deployed"
            ? "Deploying your controller, this may take up to 10 minutes..."
            : "Loading controller's data..."
        }
        className={controllerReady ? "hidden" : "fill"}
      >
        <Navbar bg="dark" expand="md" bg="primary">
          <Navbar.Brand href="#home">
            <img
              src="./assets/controllific-logo.png"
              className="inverted-logo d-inline-block align-middle"
              style={{ width: "50%", maxWidth: "300px" }}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto text-right">
              <Nav.Link
                style={{ color: "white" }}
                href="https://controllific.chargebeeportal.com/portal/"
              >
                Invoices
              </Nav.Link>

              {/* <Nav.Link href="#link">Logout</Nav.Link> */}
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Container fluid className="p-0" className="fill">
          <Row>
            {/* <Col sm="auto">
            <ProSidebar>
              <SidebarHeader>
                <img
                  src="./controllific-logo.png"
                  className="inverted-logo horizontal-center-img"
                  style={{ width: "95%" }}
                ></img>
              </SidebarHeader>
              <Menu iconShape="square">
                <MenuItem icon={<ArrowRight />}>Dashboard</MenuItem>
                <SubMenu title="Components" icon={<ArrowRight />}>
                  <MenuItem>Component 1</MenuItem>
                  <MenuItem>Component 2</MenuItem>
                </SubMenu>
              </Menu>
            </ProSidebar>
          </Col> */}
            <Col xs={12}>
              {/* <main>
                <h1>{controllerName}</h1>
              </main> */}
              <Container fluid>
                {/* <Row className="mt-3 justify-content-md-center">
                  <Col xs="auto">
                    <center>
                      <p
                        className="text-secondary mb-0"
                        style={{ fontSize: "0.25em" }}
                      >
                        Controller name
                      </p>
                      <h1 className="mt-0">{controllerName}</h1>
                    </center>
                  </Col>
                </Row> */}
                <Row className="mt-3">
                  <Col xs={12}>
                    <Breadcrumb>
                      {/* <Breadcrumb.Item href="#">Home</Breadcrumb.Item> */}
                      <Breadcrumb.Item>Controller</Breadcrumb.Item>
                      <Breadcrumb.Item active>{controllerName}</Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="12" sm="6" md="4" xl="3" className="mb-2">
                    <Card className="h-100">
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        <Card.Title>Hello there,</Card.Title>
                        <Card.Text>
                          Here is your controller:{" "}
                          <a
                            href={`https://${controllerName}.controllific.com`}
                          >
                            {controllerName}.controllific.com
                          </a>
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="4" xl="3" className="mb-2">
                    <Card className="h-100">
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        <Card.Title>Initial credentials</Card.Title>
                        <Card.Text>
                          To authenticate into your controller, use the
                          following credentials:{" "}
                          <ul
                            style={{ listStyle: "none", margin: 0, padding: 0 }}
                          >
                            <li>
                              <Badge variant="primary">Username: admin</Badge>
                            </li>
                            <li>
                              <Badge variant="primary">
                                Password: {initialPassword}
                              </Badge>
                            </li>
                          </ul>
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="4" xl="3" className="mb-2">
                    <Card className="h-100">
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        <Card.Title>Device adoption</Card.Title>
                        <Card.Text>
                          To adopt a device with this controller, set the inform
                          URL to:{" "}
                          {/* <ul style={{ listStyle: "none" }}>
                            <li> */}
                          <Badge variant="primary">
                            http://{controllerName}.controllific.com:8080/inform
                          </Badge>
                          {/* </li>
                          </ul> */}
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6" md="4" xl="3" className="mb-2">
                    <Card className="h-100">
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        <Card.Title>Guest portal</Card.Title>
                        <Card.Text>
                          We support guest portals via HTTP and HTTPS; to enable
                          it in the controller, make sure to select the
                          <i>"Redirect using hostname"</i> with the following
                          hostname:{" "}
                          <Badge variant="primary">
                            {controllerName}.controllific.com
                          </Badge>
                          {/* </li>
                          </ul> */}
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="mb-2">
                    <Card className="h-100">
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        <Card.Title>
                          External backups
                          <small
                            className="text-muted"
                            style={{ display: "block" }}
                          >
                            Last 14 days
                          </small>
                        </Card.Title>
                        <Card.Text>
                          <ListGroup className="download-backups-list">
                            {externalBackups.map((key) => {
                              return (
                                <ListGroup.Item
                                  action
                                  href={`/api/controllers/backups?filename=${key}`}
                                >
                                  <Download
                                    style={{ marginRight: "5px" }}
                                  ></Download>{" "}
                                  {key}
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                          {/* </li>
                          </ul> */}
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        {/* <p>Some content or children or something.</p> */}
      </LoadingOverlay>
    </React.Fragment>
  );
}
