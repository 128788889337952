import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ArrowRight } from "react-bootstrap-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Fade, Slide } from "react-awesome-reveal";
import Dashboard from "./components/dashboard";
import UserPrompt from "./components/userPrompt";
// import { loadReCaptcha } from "react-recaptcha-v3";

const SITE_KEY = "6LcUAdMUAAAAAERazyxIznB6bbDciTdd045kKexj";

export default function App() {
  // componentDidMount() {
  //   loadReCaptcha(your_site_key, callback);
  // }

  // useEffect(() => {
  //   loadReCaptcha("6LcUAdMUAAAAAERazyxIznB6bbDciTdd045kKexj", () => {
  //     console.log("recaptcha loaded");
  //   });
  // }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );

    window.Chargebee.init({
      site: "controllific",
      publishableKey: "live_2sncuALk2yvcukeaywGhk9EzIHysTRjcocu",
      // site: "controllific-test",
      // publishableKey: "test_ypeV3uEcDkcupRuSJMbD6cd0bIDBVV4Une",
      // domain: "http://localhost:3000"
    });
  }, []);

  return (
    <Router>
      {/* <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route
          path="/dashboard"
          render={(props) => <Dashboard {...props} />}
        ></Route>
        <Route
          exact
          path="/"
          render={(props) => <UserPrompt {...props} />}
        ></Route>
      </Switch>
      {/* </div> */}
    </Router>
  );
}

function Home({ props }) {
  const query = new URLSearchParams(props.location.search);
  console.log(query);
  return (
    <Container className="p-0" style={{ height: "100vh" }}>
      <Row
        className="justify-content-md-center align-items-center"
        style={{ height: "100%" }}
      >
        {/* <Col xs lg="2"></Col> */}
        {/* <Fade triggerOnce duration="600">
          <Slide triggerOnce direction="up" duration="600"> */}
        <Col sm="4">
          <Fade triggerOnce duration="600">
            <Slide triggerOnce direction="up" duration="600">
              <Container className="auth-inner p-0">
                <Row className="justify-content-md-center align-items-center p-4">
                  <img
                    src="./controllific-logo.png"
                    style={{ width: "250px" }}
                  ></img>
                </Row>
                {/* <Row className="justify-content-md-center align-items-center">
                  <div>Log in</div>
                </Row> */}
                <hr className="m-0 p-0" />
                <Row>
                  <Col sm="12">
                    <ButtonGroup
                      className="btn-block"
                      aria-label="Basic example"
                    >
                      <Button active className="rounded-0" variant="secondary">
                        Log in
                      </Button>
                      <Button className="rounded-0" variant="secondary">
                        Sign up
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center align-items-center mb-2"
                  style={{ padding: "2em" }}
                >
                  <Form style={{ width: "100%" }}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group> */}
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-block"
                    >
                      Log in <ArrowRight className="ml-1" size={22} />
                    </Button>
                  </Form>
                </Row>
                <Row className="justify-content-md-center align-items-center">
                  <Col sm="auto" className="justify-content-sm-center">
                    <div className="w-auto">
                      <small className="text-muted">
                        Don't remember your password?
                      </small>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-md-center align-items-center">
                  <Col sm="auto" className="justify-content-sm-center">
                    <div className="w-auto">
                      <br />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Slide>
          </Fade>
        </Col>
        {/* <Col xs lg="2"></Col> */}
      </Row>
    </Container>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
