import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ArrowRight } from "react-bootstrap-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import { Fade, Slide } from "react-awesome-reveal";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";

// import { ReCaptcha } from "react-recaptcha-v3";
//https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react
const SITE_KEY = "6LcUAdMUAAAAAERazyxIznB6bbDciTdd045kKexj";

export default function UserPrompt(props) {
  const [alert, setAlert] = React.useState(null);

  //   React.useEffect(() => {
  //     if (isSelected) {
  //       console.log("isSelected is true");
  //       // we need to attach transformer manually
  //       trRef.current.nodes([shapeRef.current]);
  //       trRef.current.getLayer().batchDraw();
  //     }
  //   }, [isSelected]);
  // const query = new URLSearchParams(props.location.search);

  //console.log(props.location);

  // const [validated, setValidated] = useState(false);

  // useEffect(() => {
  //   window.Chargebee.init({
  //     site: "controllific",
  //     publishableKey: "live_2sncuALk2yvcukeaywGhk9EzIHysTRjcocu",
  //     // site: "controllific-test",
  //     // publishableKey: "test_ypeV3uEcDkcupRuSJMbD6cd0bIDBVV4Une",
  //     // domain: "http://localhost:3000"
  //   });
  // }, []);

  const recoverPasswordSchema = yup.object({
    email: yup
      .string()
      .email("Must be a valid email")
      .required("Email is required"),
  });

  const resetPasswordSchema = yup.object({
    password: yup.string().required(),
  });

  const loginSchema = yup.object({
    username: yup
      .string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: yup.string().required(),
  });

  const signupSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email()
      .required()
      .test(
        /* name */ "unique-email",
        /* failure message */ "this email is already in use",
        function (value) {
          console.log("about to check if email is unique");
          return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
              window.grecaptcha
                .execute(SITE_KEY, { action: "email" })
                .then((token) => {
                  // submitData(token);
                  // console.log("token to verify", token, json);
                  // const cookies = new Cookies();
                  // cookies.set("myCat", "Pacman", { path: "/" });
                  fetch("/api/emailAvailable", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: value, token: token }),
                  })
                    .then((r) => {
                      console.log("r", r, r.status);
                      resolve(r.status === 200);
                    })
                    .then((data) => {
                      console.log("data", data);
                      // return r.status !== 200;
                    })
                    .catch((err) => {
                      console.log("error: ", err);
                      reject(true);
                      // this.setState({ loginFailure: true });
                    });
                });
            });
          });
        }
        //   return fetch("/api/emailAvailable", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({ email: value }),
        //   })
        //     .then((r) => r.status === 200)
        //     .catch((err) => {
        //       console.log("error: ", err);
        //       return true;
        //     });
        // }
      ),
    password: yup.string().required(),
    subdomain: yup
      .string()
      .trim()
      .matches(/^[a-z](?:[a-z0-9-]{0,61}[a-z0-9])$/, "Subdomain is invalid")
      .required()
      .test(
        /* name */ "unique-controller",
        /* failure message */ "this subdomain is already taken",
        function (value) {
          return fetch("/api/controllerAvailable", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ controllerName: value }),
          })
            .then((r) => r.status === 200)
            .catch((err) => {
              console.log("error: ", err);
              return true;
            });
        }
      ),
  });

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   console.log("form submit", event);
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };

  const handleLoginSubmit = (json) => {
    // e.preventDefault();
    // setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "login" }).then((token) => {
        // submitData(token);
        // console.log("token to verify", token, json);
        const cookies = new Cookies();
        // cookies.set("myCat", "Pacman", { path: "/" });
        fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(json),
        })
          .then((r) => r.json())
          .then((data) => {
            console.log("data");
            console.log(data);
            // cookies.set("controllificUser", JSON.stringify(data), {
            //   maxAge: 30 * 24 * 60 * 60,
            //   path: "/",
            // });
            console.log("props.history");
            props.history.push(`/dashboard`);
            // history.push("/dashboard");
          })
          .catch((err) => {
            console.log("error: ", err);
            console.log("adding toast: ", err);
            toast.error("Your email and/or password don't match our records.");
            // this.setState({ loginFailure: true });
          });
      });
    });
  };
  // const handlePasswordResetSubmit = () => {
  //   console.log("toast");
  //   return toast("Wow so easy!");
  // };
  const handleForgotPasswordSubmit = (json) => {
    // e.preventDefault();
    // setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "forgot" })
        .then((token) => {
          // submitData(token);
          // console.log("token to verify", token, json);
          // const cookies = new Cookies();
          // cookies.set("myCat", "Pacman", { path: "/" });
          fetch("/api/recover", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(json),
          })
            .then((r) => r.json())
            .then((data) => {
              console.log("data");
              console.log(data);
              // cookies.set("controllificUser", JSON.stringify(data), {
              //   maxAge: 30 * 24 * 60 * 60,
              //   path: "/",
              // });
              // console.log("props.history");
              // props.history.push(`/dashboard`);
              // history.push("/dashboard");
            })
            .catch((err) => {
              console.log("error: ", err);
              console.log("adding toast: ", err);
              // setAlert({
              //   title: "Unable to sign you in",
              //   description:
              //     "Your email and/or password don't match our records.",
              // });
              // this.setState({ loginFailure: true });
            })
            .finally(() => {
              toast.success("An email has been sent to recover your password.");
              props.history.push(`?login`);
            });
          // props.history.push(`?login`);
        });
    });
  };

  const handleResetPasswordSubmit = (json, props) => {
    // e.preventDefault();
    // setLoading(true);
    var parsedProps = qs.parse(props.location.search);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: "reset" }).then((token) => {
        // submitData(token);
        // console.log("token to verify", json);
        // const cookies = new Cookies();
        // cookies.set("myCat", "Pacman", { path: "/" });
        const bodyData = {
          email: parsedProps.email,
          uniqueKey: parsedProps.uniqueKey,
          password: json.password,
          // toke: token,
        };
        fetch("/api/changePassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        })
          .then((r) => r.json())
          .then((data) => {
            console.log("data");
            console.log(data);
            // cookies.set("controllificUser", JSON.stringify(data), {
            //   maxAge: 30 * 24 * 60 * 60,
            //   path: "/",
            // });
            // console.log("props.history");
            // props.history.push(`/dashboard`);
            // history.push("/dashboard");
            toast.success("Password was reset successfully.");
          })
          .catch((err) => {
            console.log("error: ", err);
            toast.error(
              "There was a problem resetting your password, try again later."
            );
            // console.log("adding toast: ", err);
            // setAlert({
            //   title: "Unable to sign you in",
            //   description:
            //     "Your email and/or password don't match our records.",
            // });
            // this.setState({ loginFailure: true });
          })
          .finally(() => {
            props.history.push(`?login`);
          });
        // props.history.push(`?login`);
      });
    });
  };

  const handleSignupSubmit = (json) => {
    // e.preventDefault();
    // setLoading(true);

    // submitData(token);
    // console.log("token to verify", token, json);
    // json.token = token;
    let planFromURL = new URLSearchParams(props.location.search).get("plan");
    json.plan = planFromURL ? planFromURL : "controllific-launch-plan";
    console.log("plan", planFromURL);
    window.Chargebee.getInstance().openCheckout({
      // This function returns a promise that resolves a hosted page object.
      // If the library that you use for making ajax calls, can return a promise, you can directly return that

      hostedPage: function () {
        // We will discuss on how to implement this end point in the next step.
        return fetch(`/api/generate_hp_url`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_email: json.email,
            customer_firstName: json.firstName,
            customer_lastName: json.lastName,
            plan_id: json.plan,
            invoice_notes: json.subdomain,
          }),
        })
          .then((r) => r.json())
          .then((data) => data)
          .catch((err) => {
            console.log("error: ", err);

            // this.setState({ loginFailure: true });
          });
        // return axios
        //   .post("/generate_hp_url", {
        //     customer_id: this.customer_id,
        //     plan_id: this.plan_id
        //   })
        //   .then(response => response.data);
      },

      success: function (hostedPageId) {
        // success callback
        fetch(`/api/check_subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hostedPageID: hostedPageId,
            controllerName: json.subdomain,
            firstName: json.firstName,
          }),
        });

        // cookies.set("myCat", "Pacman", { path: "/" });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(SITE_KEY, { action: "submit" })
            .then((token) => {
              json.token = token;
              fetch("/api/registerv2", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(json),
              })
                .then((response) => {
                  console.log("registerv2 response", response);
                  return response.json(); //we only get here if there is no error
                })
                .then((data) => {
                  console.log("login data");
                  console.log(data);
                  fetch("/api/login", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      username: json.email,
                      password: json.password,
                    }),
                  })
                    .then((r) => r.json())
                    .then((data) => {
                      console.log("data from response");
                      console.log(data);
                      // setCookie({}, "controllificUser", JSON.stringify(data), {
                      //   maxAge: 30 * 24 * 60 * 60,
                      //   path: "/",
                      // });
                      // console.log("props.history");
                      props.history.push(`/dashboard?welcome=${json.plan}`);
                    })
                    .catch((err) => {
                      console.log("error: ", err);
                    });
                  // cookies.set("controllificUser", JSON.stringify(data), {
                  //   maxAge: 30 * 24 * 60 * 60,
                  //   path: "/",
                  // });
                  // history.push("/dashboard");
                  // window.Chargebee.getInstance()
                })
                .catch((err) => {
                  console.log("error: ", err);
                  // this.setState({ loginFailure: true });
                });
            });
        });
      },
    });
  };

  const formToServe = (props) => {
    // console.log("props", props, qs.parse(props.location.search));
    if (!props.location.search || props.location.search.includes("login"))
      return (
        <Formik
          validationSchema={loginSchema}
          autoComplete="on"
          onSubmit={handleLoginSubmit}
          initialValues={{
            username: "",
            password: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          key="login_form"
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              noValidate
              autoComplete="on"
              onSubmit={handleSubmit}
              style={{ width: "100%" }}
            >
              <Form.Group controlId="validationFormik01">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  autoComplete="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  // value={values.username}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationFormik02">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  // value={values.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group controlId="formBasicCheckbox">
      <Form.Check type="checkbox" label="Check me out" />
    </Form.Group> */}
              <Button variant="primary" type="submit" className="btn-block">
                Log in <ArrowRight className="ml-1" size={22} />
              </Button>
              <center>
                <div className="w-auto mt-2">
                  <small className="text-muted">
                    <Link to="?forgotPassword">
                      Don't remember your password?
                    </Link>
                  </small>
                </div>
              </center>
            </Form>
          )}
        </Formik>
      );
    else if (props.location.search.includes("signup")) {
      // console.log(
      //   "plan",
      //   new URLSearchParams(props.location.search).get("plan")
      // );
      return (
        <Formik
          validationSchema={signupSchema}
          onSubmit={handleSignupSubmit}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            subdomain: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          key="signup_form"
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                  value={values.firstName}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={values.lastName}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  value={values.email}
                  isInvalid={errors.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="subdomain">
                <Form.Label htmlFor="inlineFormInputGroup">
                  Subdomain
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    id="inlineFormInputGroup"
                    type="text"
                    name="subdomain"
                    placeholder="Subdomain"
                    onChange={handleChange}
                    value={values.subdomain}
                    isInvalid={!!errors.subdomain}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>.controllific.com</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {errors.subdomain}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-block">
                Sign up <ArrowRight className="ml-1" size={22} />
              </Button>
              <p
                className="mt-1 mb-0 text-muted"
                style={{ fontSize: "9px", lineHeight: "1.6" }}
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </p>
            </Form>
          )}
        </Formik>
      );
    } else if (props.location.search.includes("forgotPassword")) {
      return (
        <Formik
          validationSchema={recoverPasswordSchema}
          onSubmit={handleForgotPasswordSubmit}
          initialValues={{
            email: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          key="forgot_password_form"
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  value={values.email}
                  isInvalid={errors.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-block">
                Reset password <ArrowRight className="ml-1" size={22} />
              </Button>
              <p
                className="mt-1 mb-0 text-muted"
                style={{ fontSize: "9px", lineHeight: "1.6" }}
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </p>
            </Form>
          )}
        </Formik>
      );
    } else if (props.location.search.includes("resetPassword")) {
      return (
        <Formik
          validationSchema={resetPasswordSchema}
          onSubmit={(values) => handleResetPasswordSubmit(values, props)}
          initialValues={{
            password: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          key="reset_password_form"
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Form.Group controlId="email">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter new password"
                  onChange={handleChange}
                  value={values.password}
                  isInvalid={errors.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-block">
                Change password <ArrowRight className="ml-1" size={22} />
              </Button>
              <p
                className="mt-1 mb-0 text-muted"
                style={{ fontSize: "9px", lineHeight: "1.6" }}
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </p>
            </Form>
          )}
        </Formik>
      );
    }
    // console.log(search);
  };

  return (
    <Container className="p-2" style={{ height: "100%" }}>
      <Row
        className="justify-content-md-center align-items-center"
        style={{ height: "100%" }}
      >
        {/* <Toast>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{toasts.map((key) => {key}}</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
        </Toast> */}
        <div
          style={{
            padding: "1em",
            position: "absolute",
            top: "0",
            right: 0,
            width: "300px",
          }}
        >
          {alert ? <Alert variant={"danger"}>{alert.description}</Alert> : ""}
        </div>
        {/* <Col xs lg="2"></Col> */}
        {/* <Fade triggerOnce duration="600">
          <Slide triggerOnce direction="up" duration="600"> */}
        <Col xs="12" sm="12" md="6">
          <Fade triggerOnce duration="600">
            <Slide triggerOnce direction="up" duration="600">
              <Container className="auth-inner p-0">
                <Row className="justify-content-xs-center align-items-center p-4">
                  <Col xs="12">
                    <img
                      src="./assets/controllific-logo.png"
                      style={{ width: "100%", maxWidth: "250" }}
                    ></img>
                  </Col>
                </Row>
                {/* <Row className="justify-content-md-center align-items-center">
                  <div>Log in</div>
                </Row> */}
                <hr className="m-0 p-0" />
                <Row>
                  <Col sm="12">
                    <ButtonGroup
                      className="btn-block"
                      aria-label="Basic example"
                    >
                      <Button
                        active={
                          !props.location.search ||
                          (props.location.search &&
                            !props.location.search.includes("signup"))
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push("?login");
                        }}
                        className="rounded-0 shadow-none"
                        variant="secondary"
                      >
                        Log in
                      </Button>
                      <Button
                        onClick={() => props.history.push("?signup")}
                        active={
                          props.location.search &&
                          props.location.search.includes("signup")
                        }
                        className="rounded-0 shadow-none"
                        variant="secondary"
                      >
                        Sign up
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center align-items-center mb-2"
                  style={{ padding: "2em" }}
                >
                  {formToServe(props)}
                </Row>
                {/* <Row className="justify-content-md-center align-items-center">
                  <Col sm="auto" className="justify-content-sm-center">
                    <div className="w-auto">
                      <br />
                    </div>
                  </Col>
                </Row> */}
              </Container>
            </Slide>
          </Fade>
        </Col>
        {/* <Col xs lg="2"></Col> */}
      </Row>
      <ToastContainer />
    </Container>
  );
}
